<template>
	<div class="timedeal_wrapper">
		<div class="hotdeal_product" @click="$router.push(`/product/detail/${products.product[0].product_pk}`)">
			<div class="img_wrapper">
				<div class="overlay"></div>
				<img
					v-if="products.product[0].path"
					v-noimage
					:src="products.product[0].path + products.product[0].save_name"
				/>
				<img v-else v-noimage :src="products.product[0].save_name" />
			</div>
			<div class="desc_wrapper">
				<div class="desc">
					<p>
						<span>{{ products.product[0].product_name }}</span>
					</p>
					<p>
						<span class="original_price">{{ numberFormat(products.product[0].product_saleprice) }}원</span>
						<span class="discount_amount">{{ getDiscountByPercent(products.product[0]) }}%</span>
						<span class="discount_price">{{ numberFormat(products.product[0].after_discount_price) }}원</span>
					</p>
					<p>오늘 아니면 {{ numberFormat(getDicountedPrice(products.product[0])) }}원 더 비싸져요!</p>
				</div>
				<div class="countdown_wrapper">
					<Countdown
						v-if="day == '오늘'"
						:deadlineDate="getDeadlineDate()"
						:mainColor="'black'"
						:secondFlipColor="'black'"
						:mainFlipBackgroundColor="'#DCDCDC'"
						:secondFlipBackgroundColor="'#DCDCDC'"
						:showDays="false"
						:showSeconds="false"
						:labels="{}"
					/>
					<p class="hotdeal_text" v-if="day == '오늘'">
						{{ day }}의 핫딜상품이 <br />
						<strong>
							{{ remainTime.hours }}시간 {{ remainTime.minutes }}분
						</strong>
						남았습니다
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import SwiperCore, { Pagination, Autoplay } from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import 'swiper/swiper-bundle.min.css';

	import SwiperList from '@/components/molcules/Scroll/SwiperList.vue';
	//import TimeDealItem from '@/components/molcules/Product/RowProductItem.vue';

	import { ref, computed } from 'vue';
	import { numberFormat } from '@/utils/numberFormat';
	import { Countdown } from 'vue3-flip-countdown';
	import { useStore } from 'vuex';

	SwiperCore.use([Pagination, Autoplay]);
	export default {
		components: { SwiperList, Swiper, SwiperSlide, Countdown },
		props: {
			day: String,
			//currentDay: Number,
			products: Object,
		},
		mixins: [numberFormat],

		setup(props, { emit }) {
			const store = useStore();

			const onClickTimeDealNext = () => {
				emit('onClick-next');
			};

			const getDiscountByPercent = (product) => {
				if (product.discount_unit === '원') {
					return Math.ceil((product.discount_price / product.product_saleprice) * 100);
				} else {
					return product.discount_price;
				}
			};

			const getDicountedPrice = (product) => {
				// % 할인의 할인 가격을 구하기 위해서
				if (product.discount_unit === '원') {
					return product.discount_price;
				} else {
					return (product.product_saleprice * product.discount_price) / 100;
				}
			};

			const getDeadlineDate = () => {
				const endDate = store.state.template.timedeal.endDate;
				const date = new Date(endDate);
				return isNaN(date.getTime()) ? new Date() : date; // 기본값으로 현재 날짜 사용
			};

			const remainTime = computed(() => {
				const endDate = new Date(store.state.template.timedeal.endDate);
				const now = new Date();
				const diff = endDate - now;

				if (diff > 0) {
					const hours = Math.floor((diff % 86400000) / 3600000); // 1 day = 86400000 ms
					const minutes = Math.floor((diff % 3600000) / 60000); // 1 hour = 3600000 ms
					return { hours, minutes };
				} else {
					return { hours: 0, minutes: 0 };
				}
			});

			return {
				onClickTimeDealNext,
				getDiscountByPercent,
				getDicountedPrice,
				getDeadlineDate,
				remainTime,
			};
		},
	};
</script>
