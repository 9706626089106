<template>
	<div ref="splashRef" class="splash_container" v-if="!splashLoaded">
		<!-- <picture>
			<source media="(min-width:768px)" srcset="@/assets/images/common/img_loading.jpg">
			<img src="@/assets/images/common/m_img_loading.jpg" alt="로딩" />
		</picture> -->
		<div class="splash_wrapper">
			<div class="text_box">
				<img src="@/assets/images/common/img_text_2.png" alt="텍스트" />
			</div>
			<div class="img_box">
				<img src="@/assets/images/common/img_girl_2.png" alt="여성이미지" />
			</div>
		</div>
	</div>
	<Alert />
	<Confirm />
	<Loading />

	<Header :scrollDirection="scrollDirection" :isScrollTop="isScrollTop" />

	<router-view @home-loading="homeLoading" />

	<!-- 메인페이지가 로딩될 때 푸터만 먼저 보이는 것을 방지하기 위해 v-if 걸어놓음 -->
	<Footer v-if="!isFooterLoading" />

	<BottomNav v-if="$route.name !== 'ProductDetail'" />
	<!-- <VueQueryDevTools /> -->
</template>

<script>
	import Header from '@/components/common/Header.vue';
	import Footer from '@/components/common/Footer.vue';
	import Alert from '@/components/Alert.vue';
	import Loading from '@/components/common/Loading.vue';
	import Confirm from '@/components/Confirm.vue';
	import BottomNav from '@/components/common/BottomNav.vue';
	import { ref } from 'vue';
	import { computed, watchEffect, watch, onMounted, onBeforeMount } from '@vue/runtime-core';
	import { useStore } from 'vuex';
	import useAxios from './compositions/useAxios';
	import { useQuery } from 'vue-query';

	export default {
		name: 'App',
		components: {
			Header,
			Footer,
			Alert,
			Loading,
			Confirm,
			BottomNav,
		},
		setup() {
			const store = useStore();
			const route = useRoute();
			const { fetchData, postData } = useAxios();

			onMounted(async () => {
				const { ip } = await (async () => {
					return await (await fetch('https://api.ipify.org?format=json')).json();
				})();
				await postData('/saveVisitor', { ip });
			});

			onMounted(async () => {
				// 타인에게 공유받은 상품정보 url 안에 추천인 코드를 박아놔서 타고 들어오면 code값을 세션스토리지에 저장해놓음
				await router.isReady();
				const inviteCode = route.query.code;
				if (inviteCode !== undefined) {
					window.sessionStorage.setItem('invite_code', inviteCode);
				}
			});

			const { isLoading, data: templateData } = useQuery(
				'template',
				() => {
					return new Promise((resolve) =>
						fetchData(
							'/getTemplate',
							(data) => {
								setLocalStorageLogo('basicLogo', data.basic_logo.path + data.basic_logo.save_name);
								setLocalStorageLogo(
									'whiteLogo',
									data.white_logo ? data.white_logo?.path + data.white_logo?.save_name : '',
								);
								setLocalStorageLogo(
									'black_logo',
									data.black_logo ? data.black_logo?.path + data.black_logo?.save_name : '',
								);
								store.commit('template/setTemplate', data);
								resolve(data);
							},
							{},
							false,
						),
					);
				},
				{
					refetchInterval: 300 * 1000,
				},
			);

			function setLocalStorageLogo(key, value) {
				localStorage.setItem(key, value);
			}

			const theme = computed(() => store.getters['template/theme']);
			watchEffect(() => {
				if (theme.value.color) {
					import(`@/assets/css/color_custom/${theme.value.color}.css`)
						.then(() => import(`@/assets/css/overwrite.css`))
						.catch((err) => console.log('getTemplate color'));
				}
				if (theme.value.design) {
					import(`@/assets/css/${theme.value.design}.css`)
						.then(() => import(`@/assets/css/overwrite.css`))
						.catch((err) => console.log('get_template design'));
				}
			});

			let isFooterLoading = ref(true);
			const homeLoading = () => {
				isFooterLoading.value = false;
			};

			return {
				...useHeaderScroll(),
				...useSplash(),
				isFooterLoading,
				homeLoading,
			};
		},
	};

	const useHeaderScroll = () => {
		const store = useStore();
		const waiting = ref(false);
		const scrollTrace = ref(0);

		const scrollDirection = ref(0);
		const isScrollTop = ref(true);
		const handleScrollHeader = () => {
			if (!waiting.value) {
				waiting.value = true;

				const nextScrollTop = window.scrollY;
				if (nextScrollTop <= 100) isScrollTop.value = true;
				else isScrollTop.value = false;
				if (nextScrollTop > scrollTrace.value) {
					scrollDirection.value = -1; // down
				} else if (nextScrollTop < scrollTrace.value) {
					scrollDirection.value = 1; // up
				}

				scrollTrace.value = nextScrollTop;
				setTimeout(() => {
					waiting.value = false;
				}, 100);
			}
		};
		watch(isScrollTop, (value) => store.commit('setScrollInfo', { isScrollTop: value }));
		watch(scrollDirection, (value) => store.commit('setScrollInfo', { scrollDirection: value }));

		onMounted(() => {
			document.addEventListener('scroll', handleScrollHeader);
			window.addEventListener('scroll', () => {
				document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
			});
		});

		return {
			scrollDirection,
			isScrollTop,
		};
	};

	import { modalOpenScrollFix, modalCloseScrollRestore } from './utils/modalScrollControl';
	import { useRoute } from 'vue-router';
	import router from './router';
	const useSplash = (duration = 2000) => {
		// const isLoaded = window.sessionStorage.getItem('init');
		const store = useStore();
		const splashLoaded = computed(() => store.getters['template/splashLoaded']);
		const splashRef = ref(null);

		if (!splashLoaded.value) {
			modalOpenScrollFix();
			setTimeout(() => {
				if (splashRef.value) {
					splashRef.value.style.opacity = 0;
				}
			}, duration - 250);

			setTimeout(() => {
				store.commit('template/setSplashLoaded', true);
				modalCloseScrollRestore();
			}, duration);
		}

		return {
			splashRef,
			splashLoaded,
		};
	};
</script>

<style>
	@import './assets/css/reset.css';
	@import './assets/css/common.css';
	@import './assets/css/layout_2.css';
	/* @import './assets/css/theme_01.css'; */
	/* @import './assets/css/color_custom/purplish.css'; */
	/* @import './assets/css/overwrite.css'; */
	.splash_container {
		width: 100vw;
		height: 100vh;
		background-color: #e1e2e4;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: opacity 200ms linear;
		overflow: hidden;
		z-index: 300;
		z-index: 9999;
	}
	.splash_container .splash_wrapper {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
	}
	.splash_container .text_box {
		padding: 0 6rem;
		width: 24rem;
		position: absolute;
		top: 6rem;
		left: 0;
		z-index: 100;
	}
	.splash_container .img_box {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.splash_container img {
		height: 100%;
		display: flex;
		object-fit: cover;
	}
	@media screen and (max-width: 768px) {
		.splash_container .splash_wrapper .text_box img {
			max-width: 20rem;
		}
		.splash_container .splash_wrapper .img_box {
			height: 60%;
		}
	}
</style>
